@use 'functions' as *;
//------------------------------------------------------------
//print
//------------------------------------------------------------
@media print {
  .js-animation-to-up-el,
  .js-animation-to-left-el,
  .js-animation-to-right-el {
    opacity: 1!important;
    visibility: inherit!important;
    transform: translate(0px, 0px)!important;
  }
}

.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0;
}

.col-1 {
  flex: 0 0 calc(100% / 12 * 1);
  max-width: calc(100% / 12 * 1);
  padding: 0;
  position: relative;
  width: 100%;
}

.col-2 {
  flex: 0 0 calc(100% / 12 * 2);
  max-width: calc(100% / 12 * 2);
  padding: 0;
  position: relative;
  width: 100%;
}

.col-3 {
  flex: 0 0 calc(100% / 12 * 3);
  max-width: calc(100% / 12 * 3);
  padding: 0;
  position: relative;
  width: 100%;
}

.col-4 {
  flex: 0 0 calc(100% / 12 * 4);
  max-width: calc(100% / 12 * 4);
  padding: 0;
  position: relative;
  width: 100%;
}

.col-5 {
  flex: 0 0 calc(100% / 12 * 5);
  max-width: calc(100% / 12 * 5);
  padding: 0;
  position: relative;
  width: 100%;
}

.col-6 {
  flex: 0 0 calc(100% / 12 * 6);
  max-width: calc(100% / 12 * 6);
  padding: 0;
  position: relative;
  width: 100%;
}

.col-7 {
  flex: 0 0 calc(100% / 12 * 7);
  max-width: calc(100% / 12 * 7);
  padding: 0;
  position: relative;
  width: 100%;
}

.col-8 {
  flex: 0 0 calc(100% / 12 * 8);
  max-width: calc(100% / 12 * 8);
  padding: 0;
  position: relative;
  width: 100%;
}

.col-9 {
  flex: 0 0 calc(100% / 12 * 9);
  max-width: calc(100% / 12 * 9);
  padding: 0;
  position: relative;
  width: 100%;
}

.col-10 {
  flex: 0 0 calc(100% / 12 * 10);
  max-width: calc(100% / 12 * 10);
  padding: 0;
  position: relative;
  width: 100%;
}

.col-11 {
  flex: 0 0 calc(100% / 12 * 11);
  max-width: calc(100% / 12 * 11);
  padding: 0;
  position: relative;
  width: 100%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0;
  position: relative;
  width: 100%;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

@media screen and (min-width: 780px) {
  .offset-md-1,
  .offset-md-2,
  .offset-md-3,
  .offset-md-4,
  .offset-md-5,
  .offset-md-6,
  .offset-md-7,
  .offset-md-8,
  .offset-md-9,
  .offset-md-10,
  .offset-md-11 {
    margin-left: 0;
  }
}

@include pri-mq-up(map-get($grid-breakpoints, 'md')) {
  .col-md-1 {
    flex: 0 0 calc(100% / 12 * 1);
    max-width: calc(100% / 12 * 1);
    padding: 0;
    position: relative;
    width: 100%;
  }

  .col-md-2 {
    flex: 0 0 calc(100% / 12 * 2);
    max-width: calc(100% / 12 * 2);
    padding: 0;
    position: relative;
    width: 100%;
  }

  .col-md-3 {
    flex: 0 0 calc(100% / 12 * 3);
    max-width: calc(100% / 12 * 3);
    padding: 0;
    position: relative;
    width: 100%;
  }

  .col-md-4 {
    flex: 0 0 calc(100% / 12 * 4);
    max-width: calc(100% / 12 * 4);
    padding: 0;
    position: relative;
    width: 100%;
  }

  .col-md-5 {
    flex: 0 0 calc(100% / 12 * 5);
    max-width: calc(100% / 12 * 5);
    padding: 0;
    position: relative;
    width: 100%;
  }

  .col-md-6 {
    flex: 0 0 calc(100% / 12 * 6);
    max-width: calc(100% / 12 * 6);
    padding: 0;
    position: relative;
    width: 100%;
  }

  .col-md-7 {
    flex: 0 0 calc(100% / 12 * 7);
    max-width: calc(100% / 12 * 7);
    padding: 0;
    position: relative;
    width: 100%;
  }

  .col-md-8 {
    flex: 0 0 calc(100% / 12 * 8);
    max-width: calc(100% / 12 * 8);
    padding: 0;
    position: relative;
    width: 100%;
  }

  .col-md-9 {
    flex: 0 0 calc(100% / 12 * 9);
    max-width: calc(100% / 12 * 9);
    padding: 0;
    position: relative;
    width: 100%;
  }

  .col-md-10 {
    flex: 0 0 calc(100% / 12 * 10);
    max-width: calc(100% / 12 * 10);
    padding: 0;
    position: relative;
    width: 100%;
  }

  .col-md-11 {
    flex: 0 0 calc(100% / 12 * 11);
    max-width: calc(100% / 12 * 11);
    padding: 0;
    position: relative;
    width: 100%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
    position: relative;
    width: 100%;
  }

  .offset-md-1 {
    margin-left: calc(100% / 12 * 1);
  }

  .offset-md-2 {
    margin-left: calc(100% / 12 * 2);
  }

  .offset-md-3 {
    margin-left: calc(100% / 12 * 3);
  }

  .offset-md-4 {
    margin-left: calc(100% / 12 * 4);
  }

  .offset-md-5 {
    margin-left: calc(100% / 12 * 5);
  }

  .offset-md-6 {
    margin-left: calc(100% / 12 * 6);
  }

  .offset-md-7 {
    margin-left: calc(100% / 12 * 7);
  }

  .offset-md-8 {
    margin-left: calc(100% / 12 * 8);
  }

  .offset-md-9 {
    margin-left: calc(100% / 12 * 9);
  }

  .offset-md-10 {
    margin-left: calc(100% / 12 * 10);
  }

  .offset-md-11 {
    margin-left: calc(100% / 12 * 11);
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  width: 100%;
}
