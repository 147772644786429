@use "../_global/import" as *;
/* -----------------------------------------------------
	header
----------------------------------------------------- */
header {
  @extend .container;
  @extend .sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  .inner {
    max-width: 100%;
    @include flex;
    @include flexMdl;
    @include mq() {
      height: vw(120);
      padding: 0 vw(40);
    }
  }
  .menu {
    position: absolute;
    top: 50px;
    right: calc(50px + vw(35));
    transition: all 0.5s ease 0s;
    @include mq(lg) {
      right: 80px;
    }
    @include mq() {
      top: vw(50);
      right: vw(50);
    }
    a {
      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
  .gmenu {
    @extend .container;
    height: 100%;
    background:rgba(255, 255, 255, 0.91);
    z-index: 2;
    position: fixed;
    top: 0;
    right: 0;
    opacity: 0;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    transition: all 0.5s ease 0s;
    visibility: hidden;
    transform: translateX(100%);
    @include mq(lg) {
      right: calc(50% - 960px);
    }
    &::-webkit-scrollbar {
      display: none;
    }
    ul {
      width: 100%;
      margin: 0 auto;
      padding: 32px 128px 0;
      @include mq() {
        padding: vw(32) vw(48) 0;
      }
    }
    ul li ul {
      width: 96%;
      margin: 0 0 0 auto;
      padding: 0;
    }
    li {
      font-size: 15px;
      line-height: 1.3;
      display: block;
      font-weight: 400;
      font-style: normal;
      @include mq() {
        font-size: calc(vw(15)*1.5);
      }
      &:not(:last-child) {
        border-bottom: solid 1px #a3a3a3;
      }
      li {
        border-bottom: none !important;
        width: 90%;
        margin: 0 auto;
        display: flex;
        &::before {
          border-top: solid 1px #a3a3a3;
          content: "";
          width: 2em;
          margin: .5em 1em 0 0;
        }
        a {
          line-height: 1;
          font-weight: 400;
          padding: 0 0 24px;
          font-size: 12px;
          @include mq() {
            padding: 0 0 calc(vw(24)*1.5);
            font-size: calc(vw(12)*1.5);
          }
        }
      }
      @include mq() {
        width: 100%;
      }
      a {
        display: block;
        padding: 20px 0;
        color: #080808;
        @include mq() {
          width: 90%;
          margin: 0 auto;
          padding: vw(20)*1.5 0;
        }
      }
    }
  }
  .open {
    &.gmenu {
      opacity: 1 !important;
      transition: 0.7s;
      visibility: visible;
      display: block;
      transform: translateX(0);
    }
  }
}

/*==================================
menu
==================================*/
.menu-trigger,
.menu-trigger span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
}

.menu-trigger {
  position: relative;
  width: 50px;
  height: 17px;
  z-index: 3;
}

.menu-trigger span {
  position: absolute;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: #59525D;
}

.menu-trigger span:nth-of-type(1) {
  top: 0;
}

.menu-trigger span:nth-of-type(2) {
  top: 8px;
}

.menu-trigger span:nth-of-type(3) {
  bottom: 0;
  width: 50%;
}

.menu-trigger span:nth-of-type(1) {
  -webkit-animation: menu-bar01 0.75s forwards;
  animation: menu-bar01 0.75s forwards;
}

@-webkit-keyframes menu-bar01 {
  0% {
    -webkit-transform: translateY(8px) rotate(45deg);
  }

  50% {
    -webkit-transform: translateY(8px) rotate(0);
  }

  100% {
    -webkit-transform: translateY(0) rotate(0);
  }
}

@keyframes menu-bar01 {
  0% {
    transform: translateY(8px) rotate(45deg);
  }

  50% {
    transform: translateY(8px) rotate(0);
  }

  100% {
    transform: translateY(0) rotate(0);
  }
}

.menu-trigger span:nth-of-type(2) {
  transition: all 0.25s 0.25s;
  opacity: 1;
}

.menu-trigger span:nth-of-type(3) {
  -webkit-animation: menu-bar02 0.75s forwards;
  animation: menu-bar02 0.75s forwards;
}

@-webkit-keyframes menu-bar02 {
  0% {
    -webkit-transform: translateY(-8px) rotate(-45deg);
  }

  50% {
    -webkit-transform: translateY(-8px) rotate(0);
  }

  100% {
    -webkit-transform: translateY(0) rotate(0);
  }
}

@keyframes menu-bar02 {
  0% {
    transform: translateY(-8px) rotate(-45deg);
  }

  50% {
    transform: translateY(-8px) rotate(0);
  }

  100% {
    transform: translateY(0) rotate(0);
  }
}

.active span:nth-of-type(1) {
  -webkit-animation: active-menu-bar01 0.75s forwards;
  animation: active-menu-bar01 0.75s forwards;
  width: 80%;
}

@-webkit-keyframes active-menu-bar01 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
  }

  50% {
    -webkit-transform: translateY(8px) rotate(0);
  }

  100% {
    -webkit-transform: translateY(8px) rotate(45deg);
  }
}

@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }

  50% {
    transform: translateY(8px) rotate(0);
  }

  100% {
    transform: translateY(8px) rotate(45deg);
  }
}

.active span:nth-of-type(2) {
  opacity: 0;
  width: 80%;
}

.active span:nth-of-type(3) {
  -webkit-animation: active-menu-bar03 0.75s forwards;
  animation: active-menu-bar03 0.75s forwards;
  width: 80%;
}

@-webkit-keyframes active-menu-bar03 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
  }

  50% {
    -webkit-transform: translateY(-8px) rotate(0);
  }

  100% {
    -webkit-transform: translateY(-8px) rotate(-45deg);
  }
}

@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }

  50% {
    transform: translateY(-8px) rotate(0);
  }

  100% {
    transform: translateY(-8px) rotate(-45deg);
  }
}

header ul.c-gmenu-top {
  @media screen and (max-width: 1105px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @include mq() {
    padding-left: vw(48);
    padding-right: vw(48);
  }
}

.c-gmenu-top li {
  border-bottom: solid 1px #a3a3a3;
}

.c-gmenu-bottom {
  width: 100%;
  padding: 0 128px;
  @media screen and (max-width: 1105px) {
    padding: 0 50px;
  }
  @include mq() {
    padding: 0 vw(30);
  }
}

header .c-gmenu-item ul {
  padding: 10px 24px 0;
  @include mq() {
    padding: vw(0) vw(24) 0;
    li {
      width: 100%;
    }
  }
}

.c-gmenu-item>ul {
  padding-top: 0;
}

.gmenu .border-top {
  border-top: solid 1px #a3a3a3;
  &-sp-none {
    @include mq() {
      border-top: 0;
    }
  }
}

.gmenu .border-bottom {
  border-bottom: solid 1px #a3a3a3;
  &-sp-none {
    @include mq() {
      border-bottom: 0;
    }
  }
}

.gmenu .colmun2-first {
  padding: 10px 6px 0 0;
  @include mq() {
    padding: vw(10) vw(24) 0;
  }
  &>li {
    border-bottom: 0!important;
  }
}

.gmenu .colmun2-second {
  padding: 10px 0 0 0;
  @include mq() {
    padding: vw(0) vw(24) 0;
  }
  li li {
    @include mq() {
      padding-top: 0!important;
    }
  }
}
