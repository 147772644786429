@use "functions" as *;
//------------------------------------------------------------
//
//------------------------------------------------------------
a:hover {
  opacity: 0.7;
  text-decoration: underline!important;
  color: inherit;
}

p:first-child,
ul:first-child,
ol:first-child,
li:first-child {
  margin-top: 0;
}

p:last-child,
ul:last-child,
ol:last-child,
li:last-child {
  margin-bottom: 0;
}

.sans-serif {
  font-family: 'Noto Sans JP', sans-serif;
  // font-weight: 100;
  font-weight: 400;
  // font-weight: 700;
}

.serif {
  font-family: 'Noto Serif JP', serif;
  // font-weight: 200;
  font-weight: 400;
  // font-weight: 700;
}

// .fw-thin {
//   font-weight: 100;
//   font-weight: 200;
// }

.fw-bold {
  font-weight: 700;
}

.fs-italic {
  font-style: italic;
}

@include mq() {
  br.sp-none {
    display: none;
  }
}

.w100 {
  width: 100%;
}
