@use "sass:math";
/* Bootstrap を読み込む */
@use "~bootstrap/scss/bootstrap-reboot.scss";
// @use "~bootstrap/scss/bootstrap-grid.scss";
//------------------------------------------------------------
//bootstrap mediaquery
//------------------------------------------------------------
$grid-breakpoints: (
  xs: 0,
  // sm: 375px,
  md: 781px,
  lg: 1920px
);

// print対応メディアクエリ
$minWidth: min-width;
$maxWidth: max-width;

@mixin pri-mq-up($md) {

  @media print,
  screen and ($minWidth: $md) {
    @content;
  }
}

//下記は今回使わない
// @mixin pri-mq-dw($brp) {
//   @media print,
//   screen and ($maxWidth: $brp) {
//     @content;
//   }
// }
// sample
// @include pri-mq-up(map-get($grid-breakpoints, 'md')) {
//   .sample {
//     background: red;
//   }
// }

//------------------------------------------------------------
//mediaquery
//------------------------------------------------------------
$break: 780;
$breakpoints: (
  'sp': 'screen and (max-width: 780px)',
  'pc': 'screen and (min-width: 781px)',
  'lg': 'screen and (min-width: 1920px)',
  ) !default;

@mixin mq($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
    }
}

//------------------------------------------------------------
//functions
//------------------------------------------------------------
@mixin set-margin($direction, $value) {
  margin-#{$direction}: $value;
}

@function get_vw($size, $viewport: 780) {
  $rate: calc(100 / $viewport);
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size: 10) {
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

.vw {
  @include fz_vw(16);
}

@function vw($px, $viewport: null) {
  @if $viewport == null {
    $viewport: $break;
  }

  @return calc($px / $viewport) * 100vw;
}

//------------------------------------------------------------
// 1920px対応mixin
//------------------------------------------------------------
@function px($vw_size, $viewport: 1920) {
  @return calc($vw_size * $viewport * 1px / 100);
}

//------------------------------------------------------------
//flexBox simple
//------------------------------------------------------------
@mixin flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

@mixin flexwrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

//------------------------------------------------------------
// flexBox 縦中央ぞろえ
//------------------------------------------------------------
@mixin flexMdl {
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

//------------------------------------------------------------
// flexBox 横中央寄せ
//------------------------------------------------------------
@mixin flexcenter {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

//------------------------------------------------------------
// flexBox 左右均等揃え  両端余白なし
//------------------------------------------------------------
@mixin flexbetween {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

//------------------------------------------------------------
// flexBox 左右均等揃え  両端余白あり
//------------------------------------------------------------
@mixin flexaround {
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
