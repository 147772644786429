@use "_global/import" as *;

body {
  @extend .sans-serif;
}

.l-data {
  margin: 0 auto;
  padding: 0 15px;
  @extend .container;
  max-width: 1080px;
  .c-data {
    @include flex;
    @include flexwrap;
    overflow: hidden;
    .c-data-item:last-child {
      padding-left: 20px;
      @include mq() {
        padding-left: 0;
      }
    }
    .row {
      margin-bottom: 30px;
      @include mq() {
        margin-bottom: vw(30);
      }
    }
  }
}

.l-header {
  @extend .container;
  margin-bottom: 15px;
  @include mq() {
    margin-bottom: vw(15);
  }
  &.p-notfound-header {
    margin-bottom: 170px;
    background: rgb(248,249,250);
    background: linear-gradient(90deg, rgba(248,249,250,1) 0%, rgba(237,240,249,1) 55%, rgba(236,240,248,1) 100%);
    padding-top: 55px;
    @media screen and (max-width: 1105px) {
      padding-top: vw(55);
    }
    @include mq() {
      margin-bottom: vw(170);
    }
    .p-header-row {
      margin-top: 0;
    }
    .p-navbar-main {
      @include pri-mq-up(map-get($grid-breakpoints, 'md')) {
        margin-bottom: 77px;
      }
    }
  }
}

.p-header-bar {
  background: rgb(170,165,195);
  background: linear-gradient(90deg, rgba(170,165,195,1) 0%, rgba(178,142,180,1) 50%, rgba(189,134,173,1) 100%);
  height: 16px;
}

.p-header-row {
  margin-top: 55px;
  @media screen and (max-width: 1105px) {
    margin-top: vw(55);
  }
  .p-logo-wrap {
    margin-left: 3%;
    @media screen and (max-width: 1105px) {
      margin-left: 0;
      margin-bottom: vw(30);
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
      position: relative;
      width: 100%;
    }
  }
  .p-logo {
    width: 20%;
    margin: 0 auto;
    @include pri-mq-up(map-get($grid-breakpoints, 'md')) {
      width: 82%;
    }
    @media screen and (max-width: 1105px) {
      width: 33%;
    }
    .p-notfound-logo {
      @include pri-mq-up(map-get($grid-breakpoints, 'md')) {
        width: 72%;
      }
    }
    img {
      width: 100%;
    }
  }
  .p-navbar-sub {
    margin-bottom: 0;
  }
}

.p-navbar-main-wrap {
  flex: 0 0 60.6666667%;
  max-width: 60.6666667%;
  padding: 0;
  position: relative;
  width: 100%;
  &:has(.l-bottom-nav) {
    padding-right: 0;
  }
  @media screen and (max-width: 1105px) {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding: 0 3%;
  }
}

.p-navbar-main {
  display: none;
  @include pri-mq-up(map-get($grid-breakpoints, 'md')) {
    margin: 45px auto 90px;
    display: block;
    width: 100%;
    top: 0;
    .p-navbar-nav {
      max-width: 800px;
      margin: 0 auto;
      display: flex;
      flex-basis: auto;
      list-style: none;
      padding: 0;
      .p-nav-item {
        position: relative;
        flex: auto;
        text-align: center;
        padding: 0 15px;
        &::before {
          content: "";
          position: absolute;
          bottom: -20px;
          left: 50%;
          transform: translateX(-50%);
          border: 6px solid transparent;
          border-top: 7px solid #9C83A5;
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          height: 60%;
          transform: translateY(-50%);
          border-right: 1px solid #707070;
        }
        &:last-of-type::after {
          border-right: none;
        }
        .p-nav-link {
          color: #59525D;
          font-size: 19px;
          font-weight: 600;
          text-decoration: none;
        }
      }
    }
  }
}

.p-hr {
  border-bottom: 0.3px solid #9C83A5;
  padding-bottom: 90px;
  @include mq() {
    padding-bottom: vw(90);
  }
}

.p-navbar-sub {
  margin-bottom: 80px;
  @media screen and (max-width: 1105px) {
    margin: 0 auto 80px;
  }
  .p-navbar-sub-nav {
    margin: 0px auto;
    max-width: 800px;
    display: flex;
    flex-basis: auto;
    list-style: none;
    padding: 0;
    .p-nav-sub-title {
      color: #9C83A5;
      padding: 0 30px 0 0;
      white-space: nowrap;
      font-size: 15px;
      @include mq() {
        font-size: calc(vw(15)*1.5);
        line-height: 2.5;
        padding: 0 calc(vw(15)) 0 0;
      }
    }
    .p-nav-sub-item .p-navbar-sub-innner-nav {
      display: flex;
      flex-basis: auto;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      font-size: 14px;
      @include mq() {
        font-size: vw(14);
      }
      li {
        position: relative;
        text-align: center;
        padding: 0 15px;
        @include mq() {
          padding: 0 vw(15);
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          height: 60%;
          transform: translateY(-50%);
          border-right: 1px solid #707070;
          @include mq() {
            display: none;
          }
        }
        &:last-of-type::after {
          border-right: none;
        }
        .p-nav-sub-link {
          color: #59525D;
          font-size: 14px;
          text-decoration: none;
          @include mq() {
            font-size: vw(21);
            line-height: 2.75;
          }
        }
      }
    }
  }
}

.pro-page-title {
  font-size: 30px;
  padding: 40px 50px;
  margin: 0 0 60px;
  border-top: 4px solid #9C83A5;
  border-bottom: 0.3px solid #9C83A5;
  width: 100%;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: none;
    border-right: solid 15px transparent;
    border-top: solid 15px #9C83A5;
  }
  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border: none;
    border-right: solid 15px transparent;
    border-bottom: solid 15px #9C83A5;
  }
  @include mq() {
    font-size: vw(30);
    padding: vw(40) vw(50);
    margin: 0 0 calc(vw(60));
  }
}

.p-result-list {
  margin: 0 auto;
  .p-result-list-item {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0 !important;
    }
    @include mq() {
      margin-bottom: vw(30);
    }
    &-right {
      padding-left: 20px;
      @include mq() {
        padding-left: 0;
      }
    }
    p {
      font-size: 16px;
      @include mq() {
        font-size: calc(vw(16)*1.5);
      }
    }
  }
}

.p-arrow-1 {
  position: relative;
  display: inline-block;
  background: #9C83A5;
  color: #fff;
  line-height: 1.25;
  font-size: 16px;
  padding: 5px 15px 5px 30px;
  height: 30px;
  @include mq() {
    font-size: calc(vw(16)*1.5);
    padding: calc(vw(5)*1.5) calc(vw(15)*1.5) calc(vw(5)*1.5) calc(vw(30)*1.5);
    height: calc(vw(30)*1.5);
    margin-bottom: vw(10);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    vertical-align: middle;
    left: 100%;
    width: 15px;
    height: 15px;
    border: 15px solid transparent;
    border-left: 15px solid #9C83A5;
    @include mq() {
      width: calc(vw(15)*1.5);
      height: calc(vw(15)*1.5);
      border: calc(vw(15)*1.5) solid transparent;
      border-left: calc(vw(15)*1.5) solid #9C83A5;
    }
  }
}

.c-bg-dark {
  background-color: #F7F5F5;
}

.p-research {
  @extend .container;
  padding: 70px 0 0;
  @include mq() {
    padding: vw(70) 0 0;
  }
  .p-research-span {
    color: #9C83A5;
  }
}

.c-data-item {
  margin-bottom: 40px;
  font-size: 18px;
  @include mq() {
    font-size: calc(vw(18)*1.5);
    margin-bottom: vw(40);
  }
  h3 {
    margin-bottom: 1rem;
  }
  p {
    font-size: 16px;
    @include mq() {
      font-size: calc(vw(16)*1.5);
    }
  }
}

.l-data .c-data .row {
  &:last-child {
    margin-bottom: 90px;
    @include mq() {
      margin-bottom: vw(90);
    }
  }
  &:last-child .c-data-item {
    margin-bottom: 0;
  }
}

.p-sub-title-1 {
  border-radius: 10px;
  background-color: #D9D4DB;
  color: #7A6781;
  border-radius: 10px;
  padding: 15px 40px;
  font-size: 18px;
  @include mq() {
    padding: calc(vw(15)*1.5) calc(vw(40)*1.5);
    font-size: calc(vw(18)*1.5);
  }
}

.p-sub-title-2 {
  @extend .p-sub-title-1;
  background-color: #9C83A5;
  color: #fff;
}

.p-table {
  list-style: none;
  padding: 0;
  li {
    .p-table-title {
      padding-left: 10px;
      position: relative;
      &::before {
        content: "\025cf";
        color: #9C83A5;
        position: absolute;
        left: 0;
      }
    }
  }
  &-img {
    width: 100%;
  }
}

.l-bottom-nav {
  margin: 55px auto;
  @include mq() {
    margin: vw(55) auto;
  }
  .p-header-row {
    margin-top: 0;
    justify-content: center;
  }
  .p-navbar-main-wrap {
    padding-right: 0;
  }
}

.l-footer {
  @extend .container;
  .l-footer-innner {
    @extend .container;
    max-width: 1080px;
    @include mq() {
      max-width: vw(680);
    }
  }
  .c-footer-sub {
    background-color: #F2F2F2;
    padding: 100px 0 120px;
    @include mq() {
      padding: vw(100) 0 vw(120);
    }
    .c-footer-sub-inner {
      @include flex;
      flex-direction: column;
      @include pri-mq-up(map-get($grid-breakpoints, 'md')) {
        @include flexaround;
        flex-direction: row;
      }
    }
  }
}

.p-footer-sub-item {
  margin: 20px auto 0;
  text-align: center;
  background-color: #fff;
  max-width: 513px;
  width: 100%;
  padding: 50px 0;
  position: relative;
  @include pri-mq-up(map-get($grid-breakpoints, 'md')) {
    margin: 20px 20px 0;
  }
  .p-ribbon {
    position: absolute;
    left: -6px;
    top: 14px;
  }
  .p-stretched-link {
    color:inherit;
    &::after {
      content: "";
      position: absolute;
      pointer-events: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
}

.p-table-type1 {
  display: block;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  background-color: #fff;
  border-top: 1.5px solid #9C83A5;
  margin-bottom: 1rem;
  tbody {
    width: 100%;
    display: table;
  }
  tr {
    + tr {
    border-top: 1px solid rgba(156, 131, 165, 0.3);
    }
    &:last-of-type {
      border-bottom: 1.5px solid #9C83A5;
    }
  }
  .c-td-center {
    text-align: center;
  }
  .c-td-right {
    text-align: right;
  }
  td {
    width: 20%;
    height: 50px;
    padding: 15px;
    font-size: 14px;
    + td {
      border-left: 1px solid #9C83A5;
    }
  }
}

.p-notfound-page-title {
  color: #59525D;
  font-size: 24px;
  text-align: center;
  width: 100%;
  position: relative;
  margin-bottom: 145px;
  @include mq() {
    font-size: vw(24)*1.5;
    margin-bottom: vw(145);
  }
  &::after {
    position: absolute;
    content: '';
    width: 26px;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    border-top: solid 3px #8683A5;
    @include mq() {
      width: vw(26)*1.5;
      bottom: vw(-30)*1.5;
    }
  }
}

.p-notfound-wrap {
  @extend .container;
  padding: 70px 0 0;
  position: relative;
  @include mq() {
    padding: vw(70) 0 0;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(239,228,237);
    background: linear-gradient(90deg, rgba(239,228,237,1) 0%, rgba(233,237,244,1) 50%, rgba(239,228,237,1) 80%);    width:100%;
    height: 2px;
  }
  a {
    color: #1e407b;
  }
  .arrow {
    position: relative;
    padding: 0 0 0 16px;
  }
  .arrow::before {
    content: "";
    position: absolute;
    top: 50%;
    /* 縦軸をセンタリングする */
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    /* 縦軸をセンタリングする */
    border: 5px solid transparent;
    border-left: 8px solid #555;
    /* 好みで色を変えてください */
  }
}

.p-notfound-list {
  margin-bottom: 190px;
  @include mq() {
    margin-bottom: vw(190);
  }
  dt {
    font-size: 18px;
    margin-bottom: 27px;
    margin-left: 82px;
    text-indent: -57px;
    @include mq() {
      font-size: vw(18)*1.5;
      margin-bottom: vw(27);
      margin-left: vw(82);
      text-indent: vw(-57);
    }
    span {
      font-size: 30px;
      color: #9C83A5;
      margin-right: 22px;
      @include mq() {
        font-size: vw(30);
        margin-right: vw(22);
      }
    }
  }
  dd {
    margin: 0 82px;
    @include mq() {
      margin: 0 vw(82);
    }
    + dt {
      margin-top: 60px;
      @include mq() {
        margin-top: vw(60);
      }
    }
    p {
      font-size: 14px;
      @include mq() {
        font-size: vw(14)*1.5;
      }
    }
  }
}
