@use "../_global/import" as *;
/* -----------------------------------------------------
	footer
----------------------------------------------------- */
footer {
  @extend .container;
  .c-footer-main {
    background-color: #F0D7D3;
    padding: 90px 15px;
    @include mq() {
      padding: vw(90) 15px vw(90);
    }
  }
  .c-footer-copyright {
    p {
      padding: 25px 0;
      text-align: center;
    }
  }
}

.p-footer-logo {
  width: 230px;
  margin-bottom: 60px;
  @include mq() {
    width: vw(230);
    margin-bottom: vw(60);
  }
  img {
    width: 100%;
    height: auto
  }
}

.p-footer-main-inner {
  @include flexbetween;
  ul {
    padding: 0;
    li {
      list-style: none;
      white-space: nowrap;
      margin-bottom: 25px;
      font-weight: 500;
      font-size: 14px;
      a {
        color: #000;
      }
      @include mq() {
        white-space: unset;
        font-size: calc(vw(14)*1.5);
        margin-bottom: vw(25);
      }
    }
  }
}
