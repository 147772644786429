@use "../_global/import" as *;

#pagetop {
  position: fixed;
  padding: 0;
  margin: 0;
  cursor: pointer;
  bottom: 80px;
  right: calc(50px + vw(35));
  @include mq(lg) {
    right: calc(50vw - 881px);
  }
  @include mq() {
    bottom: 60px;
    right: vw(50);
  }
  img {
    width: 79px;
    height: 100px;
    @include mq() {
      width: vw(79);
      height: vw(100);
    }
  }
}
